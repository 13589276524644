const Materials = {
  Legno: {
    name: 'Legno',
    maps: {
      map: 'color.jpg',
      normalMap: 'normal.jpg',
      roughnessMap: 'roughness.jpg',
      displacementMap: 'displacement.jpg',
      aoMap: 'ao.jpg',
    },
  },
  Pelle: {
    name: 'Pelle',
    maps: {
      map: 'color.jpg',
      displacementMap: 'displacement.jpg',
      normalMap: 'normal.jpg',
      roughnessMap: 'roughness.jpg',
      metalnessMap: 'metalness.jpg',
      aoMap: 'ao.jpg',
    },
  },
  Tessuto: {
    name: 'Tessuto',
    maps: {
      map: 'color.jpg',
      normalMap: 'normal.jpg',
      metalnessMap: 'metalness.jpg',
      displacementMap: 'displacement.jpg',
      aoMap: 'ao.jpg',
    },
  },
  Metallo: {
    name: 'Metallo',
    maps: {
      map: 'color.jpg',
      normalMap: 'normal.jpg',
      roughnessMap: 'roughness.jpg',
      metalnessMap: 'metalness.jpg',
    },
  },
  Gomma: {
    name: 'Gomma',
    params: {
      color: 0x191919,
      roughness: 0.7,
    },
  },
};

export default Materials;
