import CanvasScene from "./components/CanvasScene";
import { useState } from "react";
// Materials
import Materials from "./materials";
const scoccaAvVariants = [Materials.Pelle, Materials.Tessuto];
const strutturaAvVariants = [Materials.Metallo, Materials.Legno];

function App() {
  const [scoccaVariant, setScoccaVariant] = useState(scoccaAvVariants[0]);
  const [strutturaVariant, setStrutturaVariant] = useState(
    strutturaAvVariants[0]
  );

  return (
    <div
      style={{
        height: "100vh",
        background: "pink",
        display: "grid",
        placeContent: "center",
        overflow: "hidden",
      }}
    >

      {false ? (
        <div
          style={{
            backgroundColor: "#313131",
            background:
              "linear-gradient(38deg, rgba(0,0,0,1) 0%, rgba(81,82,82,1) 100%)",
            height: "600px",
            width: "600px",
            borderRadius: "50%",
            position: "absolute",
            transform: "translate(-50%, -50%)",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        ></div>
      ) : (
        <div
          style={{
            backgroundColor: "#313131",
            background:
              "linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(81,82,82,1) 100%)",
            height: "300px",
            width: "300px",
            transform: "translate(-50%, -50%) rotate(45deg)",
            position: "absolute",
            top: "50%",
            left: "50%",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: "992px",
              top: "-38px",
              left: "0",
              height: "auto",
              width: "94%",
            }}
          >
            <img src="https://www.realpiel.it/wp-content/uploads/2019/07/RealPiel_Logo_Black.svg" />
          </div>
        </div>
      )}
      <div
        style={{
          width: "100vw",
          height: "100vh",
        }}
      >
        <CanvasScene
          rule={{
            scocca: scoccaVariant,
            struttura: strutturaVariant,
          }}
        ></CanvasScene>

        {/* SCOCCA */}
        <div
          style={{
            position: "absolute",
            top: "1rem",
            left: "2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "0.6rem",
            }}
          >
            {scoccaAvVariants.map((variant) => (
              <div
                key={variant.name}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "1rem",
                  rowGap: "0.6rem",
                }}
              >
                <div
                  style={{
                    border:
                      variant.name == scoccaVariant.name
                        ? "solid 3px #313131"
                        : "solid 3px white",
                    borderRadius: "50%",
                    height: "4rem",
                    width: "4rem",
                    cursor: "pointer",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    background: `linear-gradient(45deg, rgba(0,0,0,.3) 0%, rgba(81,82,82,.3) 100%), url('/materials/${variant.name}/color.jpg')`,
                    backgroundSize: "contain",
                  }}
                  title={variant.name}
                  onClick={(e) => {
                    setScoccaVariant(variant);
                  }}
                ></div>
                <strong
                  style={{ textTransform: "uppercase", color: "#313131" }}
                >
                  {variant.name}
                </strong>
              </div>
            ))}
          </div>
        </div>
        {/* SCOCCA */}
        <div
          style={{
            position: "absolute",
            top: "1rem",
            right: "2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "0.6rem",
            }}
          >
            {strutturaAvVariants.map((variant) => (
              <div
                key={variant.name}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginRight: "1rem",
                  rowGap: "0.6rem",
                }}
              >
                <div
                  style={{
                    border:
                      variant.name == strutturaVariant.name
                        ? "solid 3px #313131"
                        : "solid 3px white",
                    borderRadius: "50%",
                    height: "4rem",
                    width: "4rem",
                    cursor: "pointer",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    background: `linear-gradient(45deg, rgba(0,0,0,.3) 0%, rgba(81,82,82,.3) 100%), url('/materials/${variant.name}/color.jpg')`,
                    backgroundSize: "contain",
                  }}
                  title={variant.name}
                  onClick={(e) => {
                    setStrutturaVariant(variant);
                  }}
                ></div>
                <strong
                  style={{ textTransform: "uppercase", color: "#313131" }}
                >
                  {variant.name}
                </strong>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
