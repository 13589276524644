import { useGLTF, useTexture } from '@react-three/drei';
import { MeshStandardMaterial } from 'three';

function MeshMat({ mat_name, maps, params, ...props }) {
  const name = (type) => (type ? `/materials/${mat_name}/${type}` : null);

  const mapsObjKeys = maps ? Object.keys(maps) : [];

  const loadTextures = mapsObjKeys
    .map((key) => {
      return name(maps[key]);
    })
    .filter((name) => name);

  const textureMaps = useTexture(loadTextures);
  const meshProps = {};

  for (let i = 0; i < textureMaps.length; i++) {
    const map = mapsObjKeys[i];
    if (textureMaps[i]) {
      meshProps[map] = textureMaps[i];
    }
  }

  // REVIEW: mesh colors seem little lighter
  const material = new MeshStandardMaterial({
    ...meshProps,
    ...params,
    ...props,
  });

  return material;

  // return (
  //   <meshStandardMaterial
  //     onUpdate={(self) => {
  //       console.log(self);
  //     }}
  //     onBeforeCompile={(rest) => {
  //       console.log('onBeforeCompile', rest.uniforms);
  //     }}
  //     {...meshProps}
  //     {...params}
  //     {...props}
  //   />
  // );
}

export default function Model({ materials, textureMaps, params, fileName, ...props }) {
  const { nodes } = useGLTF(fileName);

  return (
    <group {...props}>
      {Object.keys(nodes).map((key, i) => {
        let node = nodes[key];
        let mat_name = materials && materials[node.name];

        if (!mat_name) {
          // console.log(`No material found for '${node.name}'`);
          return (
            <mesh key={node.uuid} geometry={node.geometry} scale={1}>
              <meshStandardMaterial {...params} />
            </mesh>
          );
        }

        let matProps = { mat_name: mat_name, maps: textureMaps, params: params };

        return (
          <mesh
            // onUpdate={(self) => {
            //   console.log('mesh update', self);
            // }}
            key={node.uuid}
            geometry={node.geometry}
            scale={1}
            material={MeshMat(matProps)}
          >
            {/* <MeshMat key={node.uuid} mat_name={mat_name} maps={textureMaps} params={params} /> */}
          </mesh>
        );
      })}
    </group>
  );
}
