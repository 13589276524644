import { Suspense, useState } from 'react';

import { Environment, OrbitControls, Plane, Stars, useProgress, Html } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { MOUSE, PerspectiveCamera } from 'three';

// Models
import GenericModel from '../Models/GenericModel';
// Materials
import Materials from '../materials';

function Loader() {
  const { progress } = useProgress();
  return (
    <Html center>
      <div id="canvas-loader">{Math.round(progress)} %</div>
    </Html>
  );
}

function Floor() {
  return (
    <Plane receiveShadow rotation={[-Math.PI / 2, 0, 0]} position={[0, -50, 0]} args={[1000, 1000]}>
      <meshStandardMaterial attach="material" color="white" />
    </Plane>
  );
}

// Custom camera
const Camera = new PerspectiveCamera();
Camera.position.set(0, 100, 150);

const scoccaAvVariants = [Materials.Pelle, Materials.Tessuto];
const strutturaAvVariants = [Materials.Metallo, Materials.Legno];

function CanvasW({ rule: { scocca, struttura } }) {
  const [autoRotate, setAutoRotate] = useState(true);
  const [autoRotateSpeed, setAutoRotateSpeed] = useState(10);
  const [scoccaVariant, setScoccaVariant] = useState(scocca);
  const [strutturaVariant, setStrutturaVariant] = useState(struttura);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden' }}>
      <Canvas camera={Camera} style={{ background: 'transparent' }}>
        <OrbitControls
          maxDistance={500}
          minDistance={120}
          autoRotate={autoRotate}
          autoRotateSpeed={autoRotateSpeed / 5}
          mouseButtons={{
            LEFT: MOUSE.ROTATE,
            // MIDDLE: MOUSE.PAN,
            // RIGHT: MOUSE.DOLLY,
          }}
        />
        {/* <Stars /> */}
        {/* <Floor /> */}
        {/* <axesHelper args={[1000, 1000, 1000]} /> */}
        <Suspense fallback={<Loader />}>
          {/* <group rotation={[-Math.PI, 0, 0]} scale={0.1}>
            <GenericModel
              materials={{ Scocca: scoccaVariant }}
              fileName="/Alu/Scocca.glb"
              textureMaps={{
                map: 'color.jpg',
                normalMap: 'normal.jpg',
                roughnessMap: 'roughness.jpg',
                displacementMap: 'displacement.jpg',
                aoMap: 'ao.jpg',
              }}
              params={{}}
            />
            <GenericModel
              materials={{ Struttura: strutturaVariant }}
              fileName="/Alu/Struttura.glb"
              textureMaps={{
                map: 'color.jpg',
                normalMap: 'normal.jpg',
                roughnessMap: 'roughness.jpg',
                displacementMap: 'displacement.jpg',
                aoMap: 'ao.jpg',
                // bumpMap: 'normal.jpg',
              }}
              params={{}}
            />
          </group> */}
          <group scale={0.1}>
            <GenericModel
              materials={{ NurScocca: scocca.name }}
              fileName="Nur/Scocca.glb"
              textureMaps={scocca.maps}
              params={scocca.params}
            />
            <GenericModel
              materials={{ NurCuscino: scocca.name }}
              fileName="Nur/Cuscino.glb"
              textureMaps={scocca.maps}
              params={scocca.params}
            />
            <GenericModel
              materials={{ NurStruttura: struttura.name }}
              fileName="Nur/Struttura.glb"
              textureMaps={struttura.maps}
              params={struttura.params}
            />
            <GenericModel
              fileName="Nur/Piedini.glb"
              // materials={{ NurPiedini: strutturaVariant.name }}
              // textureMaps={strutturaVariant.maps}
              params={Materials.Gomma.params}
            />
          </group>
          <Environment preset="warehouse" />
        </Suspense>
      </Canvas>
      <div
        style={{
          position: 'absolute',
          top: '1rem',
          left: '1rem',
          color: 'white',
          lineHeight: '0',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
        }}
      >
        {/* <div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
          Autorotate
          <input
            type="checkbox"
            name="autoRotate-status"
            id="autorotate-checkbox"
            checked={autoRotate}
            onChange={(e) => {
              setAutoRotate(e.target.checked);
            }}
          />
          </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.5rem',
          }}
        >
          Speed: {autoRotateSpeed}
          <input
            type="range"
            min={-100}
            max={100}
            step={1}
            defaultValue={20}
            name="speed-slider"
            id="speed-slider"
            onChange={(e) => {
              setAutoRotateSpeed(e.target.value);
            }}
          />
        </div> */}

        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.8rem',
          }}
        >
          <label htmlFor="scocca-select">Scocca</label>
          <select
            id="scocca-select"
            defaultValue={scoccaVariant.name}
            onChange={(e) => {
              setScoccaVariant(scoccaAvVariants.find((v) => v.name === e.target.value));
            }}
          >
            {scoccaAvVariants.map((variant) => (
              <option key={variant.name} value={variant.name}>
                {variant.name}
              </option>
            ))}
          </select>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '0.8rem',
          }}
        >
          <label htmlFor="struttura-select">Struttura</label>
          <select
            id="struttura-select"
            defaultValue={strutturaVariant.name}
            onChange={(e) => {
              setStrutturaVariant(
                strutturaAvVariants.find((variant) => variant.name === e.target.value)
              );
            }}
          >
            {strutturaAvVariants.map((variant) => (
              <option key={variant.name} value={variant.name}>
                {variant.name}
              </option>
            ))}
          </select>
        </div> */}
      </div>
    </div>
  );
}

export default CanvasW;
